type withCommonMetaTagsProps = {
  title: string
}

const withCommonMetaTags = ({ title }: withCommonMetaTagsProps) => [
  { charset: 'utf-8' },
  { title: title ?? 'Pé de Limão' },
  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
]

export default withCommonMetaTags
